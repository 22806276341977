@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-sidebar {
    background-color: #020202;
    @apply opacity-90;
  }

  .border-b-2e2e2e {
    @apply border-b;
    background-color: #2e2e2e;
  }

  .color-brand-yellow {
    color: #ffdd28;
  }
}

@layer components {

  /* Notification */
  .notification-container {
    @apply m-0 fixed box-border text-sm;
  }

  .notification-container button {
    right: -0.3em;
    top: -0.3em;
    color: #ffffff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    line-height: 1;
    font-size: 16px;
    background: 0 0;
    border: 0;
    @apply relative float-right font-bold opacity-80 p-0 cursor-pointer;
  }

  .notification {
    @apply bg-white relative pointer-events-auto overflow-hidden mb-4 p-8 rounded shadow-md shadow-gray-400 opacity-90 hover:shadow-white hover:shadow-lg hover:opacity-100 hover:cursor-pointer;
  }

  .notification-title {
    @apply font-bold text-base text-left mt-0 mb-2 h-5;
  }

  .notification-message {
    margin-left: -1px;
    @apply text-left overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .notification-image {
    @apply float-left mr-4 w-8 h-8;
  }

  /* Toast */
  .toast {
    color: #ffffff;
    padding: 20px 15px 10px 10px;
    @apply w-[365px];
  }

  .toast-top-right {
    @apply top-3 right-3 ease-in-out duration-500;
  }

  .toast-top-left {
    @apply top-3 left-3 ease-in duration-500;
  }

  .toast-bottom-left {
    @apply bottom-3 left-3 ease-in duration-500;
  }

  .toast-bottom-right {
    @apply bottom-3 right-3 ease-in-out duration-500;
  }

  .toast-success {
    @apply bg-green-500;
  }

  .toast-danger {
    @apply bg-red-500;
  }

  .toast-info {
    @apply bg-blue-400;
  }

  .toast-warning {
    @apply bg-yellow-500;
  }

  /* Modal */
  .modal-container {
    @apply bg-black bg-opacity-70 w-full h-full fixed top-0 left-0 flex items-center justify-center;
  }

  .modal-main {
    @apply flex bg-white bg-opacity-100 rounded shadow-lg w-fit h-fit max-w-[80vw] max-h-[60vw] p-4 overflow-auto;
  }

  .modal-title {
    @apply font-bold text-left text-sm sm:text-lg;
  }

  .modal-description {
    @apply text-left text-xs sm:text-base mt-2;
  }

  .modal-action-container {
    @apply flex flex-row-reverse mt-4;
  }

  /* Button */
  .button-red-outline-with-hover {
    @apply border border-red-500 bg-white rounded font-medium px-4 py-1 text-red-500 hover:bg-red-500 hover:text-white;
  }

  .button-outline-with-hover {
    @apply border rounded font-medium px-4 py-1 text-black hover:bg-black hover:text-white;
  }

  .button-yellow-outline-with-hover {
    @apply rounded-md p-2 px-4 border font-medium text-xs md:text-sm border-yellow-400 text-black drop-shadow-sm hover:bg-yellow-400 hover:text-white disabled:bg-gray-500 disabled:border-gray-500 disabled:text-white;
  }

  .button-yellow-outline-without-hover {
    @apply rounded-md p-2 px-4 border font-medium text-xs md:text-sm border-yellow-400 text-black drop-shadow-sm;
  }

  .button-gray-outline-with-hover {
    @apply rounded-md p-2 px-4 border font-medium text-xs md:text-sm border-gray-400 text-black drop-shadow-sm hover:bg-gray-400 hover:text-white;
  }

  /* Media Resource */
  .media-upload-container {
    @apply w-full p-12 border border-dashed border-black rounded-md mt-8 text-center;
  }

  .media-upload-button {
    @apply cursor-pointer p-1 text-base sm:text-xl font-sans bg-transparent hover:underline;
  }

  .input-field-full-width {
    @apply grow border border-black rounded p-1 w-full;
  }

  .midas-progress-bar {
    @apply w-full h-6 rounded overflow-hidden
  }

  .midas-progress-bar::-webkit-progress-value {
    @apply bg-yellow-400;
  }

  .midas-progress-bar::-moz-progress-bar {
    @apply bg-yellow-400;
  }

  /* Toggle Button */
  .toggle-btn {
    transition: background-color 0.1s ease, border-color 0.2 ease;
    @apply rounded-full w-14 h-7 bg-gray-500 cursor-pointer relative;
  }

  .toggle-btn .thumb {
    transform: translateX(0);
    transition: left 0.15s ease;
    left: 3px;
    transform: translateY(-50%);
    @apply h-5 w-5 bg-white rounded-full absolute top-1/2 ;
  }

  .toggle-btn.toggled {
    background-color: #ffdd28;
  }

  .toggle-btn.toggled .thumb {
    left: calc(50px - 25px);
  }
  /* Toggle Button */
}

h4 {
  color: #ffdd28;
  background-color: #020202;
  font-weight: bold;
}

h4,
.ql-size-huge {
  font-size: 25px;
}

blockquote {
  display: block;
  border-left-width: 5px;
  border-left-color: grey;
  padding-left: 10px;
}

.ql-li {
  padding-left: 50px;
}

.ql-link {
  color: blue;
  text-decoration: underline;
}

ol.ql-li {
  list-style: decimal;
}

ul.ql-li {
  list-style: disc;
}

.ql-size-small {
  font-size: 10px;
}

.ql-size-medium {
  font-size: 15px;
}

.ql-size-large {
  font-size: 20px;
}

.ql-indent-1 {
  margin-left: 100px;
}

.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}